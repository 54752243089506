<template>
  <LayoutVendor>
    <template v-slot:title>{{ title }}</template>
    <div class="order-request h-100 bg-gray-light-4">
      <div class="p-2 p-lg-5">
        <AppLoading fillSpace v-if="isLoading"></AppLoading>
        <VendorOrderTable
          v-else
          :items="orders"
          :status="status"
          :sort="sorts"
          @sort="sortBy"
          @pageChange="fetchData"
          @delete-order="fetchData"
        ></VendorOrderTable>
      </div>
    </div>
  </LayoutVendor>
</template>

<script>
import Orders from '@/shared/api/Orders';
import AppLoading from '@/shared/elements/AppLoading.vue';
import VendorOrderTable from '@/components/Partial/Orders/OrderTables/VendorOrderTable.vue';
import { STATUS } from '@/shared/constants/OrderConstants';
import LayoutVendor from '../../../../components/Partial/Layouts/LayoutVendor/LayoutVendor.vue';
import { isRoleAllowed } from '@/router/AuthorizationGate';
import { group_names } from '@/shared/constants/PersonaConstants';

export default {
  name: 'VendorOrderList',

  components: {
    AppLoading,
    VendorOrderTable,
    LayoutVendor,
  },

  data() {
    return {
      orders: {},
      sorts: [{ item: 'order_created_at', orderBy: 'DESC' }],
      page: 1,
      isLoading: false,
      status: this.$route.meta.status,
      title: this.$route.meta.title === 'Approved RFQs' ? 'RFQs' : this.$route.meta.title,
    };
  },

  watch: {
    '$route.path': {
      handler() {
        this.status = this.$route.meta.status;
        this.title = this.$route.meta.title;
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchOrders(page = 1) {
      this.isLoading = true;

      const sorts = {};

      this.sorts.forEach((value) => {
        sorts[value.item] = value.orderBy.toLowerCase();
      });

      const { owner, admin } = group_names;

      let orderParams = {
        status: this.status,
        page,
        ...sorts,
      };

      if (isRoleAllowed([owner, admin])) {
        orderParams.fetch_by_company = 1;
      }

      if (this.status === STATUS.REQUEST_APPROVED.value) {
        orderParams.exclude_quoted = 1;
      }

      if (this.status === STATUS.QUOTE_SENT.value) {
        /**
         * The status in the backend is not quote_sent for orders with quote
         * It remains are approved so we need these 2 parameters
         */
        orderParams.status = STATUS.REQUEST_APPROVED.value;
        orderParams.exclude_unquoted = 1;
        orderParams.quotation_status = 'submitted';
      }

      if (this.status === STATUS.QUOTE_APPROVED.value) {
        orderParams.quotation_status = 'approved';
        orderParams.with_quote_count = true;
      }

      orderParams.with_all_quotations = 1;

      const orders = await Orders.getOrders({
        params: orderParams,
      });

      this.isLoading = false;

      return orders.data;
    },
    async fetchData(page = 1) {
      this.orders = await this.fetchOrders(page);
    },
    async sortBy(sorts) {
      this.sorts = sorts;
      await this.fetchData();
    },
  },

  async mounted() {
    await this.fetchData();
  },
};
</script>

<style lang="scss" scoped></style>
